import mutations from './mutations';

describe('user mutations', () => {
  describe('setProfile', () => {
    it('should set state.userName, state.group and _SID_ cookie', () => {
      const FAKE_PROFILE = {
        _SID_: '666',
        username: 'name',
        group: 'administrator'
      };
      const state = {};

      mutations.setProfile(state, FAKE_PROFILE);

      expect(state).toEqual({ group: 'administrator', userName: 'name' });
    });
  });
  describe('clearProfile', () => {
    it('should set state.userName and state.group to empty string', () => {
      const state = {
        userName: 'aaa',
        group: 'bbb'
      };

      mutations.clearProfile(state);

      expect(state).toEqual({
        userName: '',
        group: '',
        sessionId: ''
      });
    });
  });
});
